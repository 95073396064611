@use "../../scss/variables";

.spinner-jimu {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    &__inner {
        text-indent: -9999em;
        margin: auto;
        position: absolute;
        right: calc(50% - 6.8px);
        top: calc(50% - 16px);
        -webkit-animation-delay: 0.16s !important;
        animation-delay: 0.16s !important;

        background: variables.$color-primary;
        -webkit-animation: loading-keys-app-loading 0.8s infinite ease-in-out;
        animation: loading-keys-app-loading 0.8s infinite ease-in-out; 
        width: 13.6px;
        height: 32px;
        
        &:before, &:after {
            background: variables.$color-primary;
            -webkit-animation: loading-keys-app-loading 0.8s infinite ease-in-out;
            animation: loading-keys-app-loading 0.8s infinite ease-in-out;
            width: 13.6px;
            height: 32px;
            
            position: absolute;
            top: 0;
            content: '';
        }
        &:before {
            left: -19.992px;
        }
        &:after {
            left: 19.992px;
            -webkit-animation-delay: 0.32s !important;
            animation-delay: 0.32s !important;
        }
    }
}

@-webkit-keyframes loading-keys-app-loading {

    0%,
    80%,
    100% {
        opacity: .75;
        box-shadow: 0 0 variables.$color-primary;
        height: 32px;
    }

    40% {
        opacity: 1;
        box-shadow: 0 -8px variables.$color-primary;
        height: 40px;
    }
}

@keyframes loading-keys-app-loading {

    0%,
    80%,
    100% {
        opacity: .75;
        box-shadow: 0 0 variables.$color-primary;
        height: 32px;
    }

    40% {
        opacity: 1;
        box-shadow: 0 -8px variables.$color-primary;
        height: 40px;
    }
}