@use "../../scss/mixins";
@use "../../scss/variables";

.language-menu {
    position: relative;
    z-index: 1;
    @include mixins.reset-ul;
    margin-left: var(--space-s);
    display:flex;
    flex-direction: row;
    li {
        font-size: var(--step-custom-small--2);
    }
    &__separator {
        color: var(--color-font-primary-soft-dynamic);
    }
    &__item {
        color: var(--color-font-primary-soft-dynamic);
        cursor: pointer;
        transition: color variables.$transition-duration-short;
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                color: variables.$color-primary;
            }
        }
        &--active {
            color: var(--color-font-primary-dynamic);
            font-weight: variables.$font-weight-bold;
            pointer-events: none;
        }
    }
}