@use "variables";

@mixin reset-ul {
    margin:0;
    padding:0;
    list-style:none;
}

@mixin reset-button {
    all: unset;
    box-sizing: border-box;
}

@mixin button-typography {
    font-family: variables.$font-family-primary;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

@mixin button {
    @include reset-button;
    @include button-typography;
    font-weight: variables.$font-weight-bold;
    font-size: var(--step-custom-small--3);
    cursor: pointer;
    border-style: solid;
    border-width: 1px;
    border-color: variables.$color-font-primary-inverted;
    color: variables.$color-font-primary;
    background-color: variables.$color-font-primary-inverted;
    text-align: center;
    padding: var(--space-2xs) var(--space-s);
    transition: color variables.$transition-duration-short, background-color variables.$transition-duration-short, border-color variables.$transition-duration-short;
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: variables.$color-font-primary-disabled;
        }
    }
}

@mixin button-primary {
    background-color: variables.$color-primary;
    color: white;
    border-color: variables.$color-primary;
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: variables.$color-font-primary;
            background-color: variables.$color-font-primary-inverted;
            border-color: variables.$color-font-primary-inverted;
        }
    }
}

@mixin back-button {
    @include reset-button;
    @include button-typography;
    cursor:pointer;
    font-size: var(--step-custom-small--4);
    transform:translateX(calc(-1 * var(--space-3xs)));
    color: variables.$color-font-primary-inverted;
    display:flex;
    align-items: center;
    justify-content: center;
}

@mixin option-price {
    color: variables.$color-soft-white-2;
    //font-size: var(--step-custom-small--1);
    margin-left: var(--space-2xs);
}

@mixin option-border {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(255,255,255,0.2);
}
