@use "../../scss/mixins";
@use "../../scss/variables";

.thumbnail-with-text {
    @include mixins.reset-button;
    color: variables.$color-font-primary-inverted;
    width:100%;
    margin-bottom: var(--space-m);
    img {
        display:block;
        width: var(--space-2xl);
    }
    &--active {
        //color: variables.$color-font-primary;
        //background-color: variables.$color-font-primary-inverted;
        .thumbnail-with-text__image-container {
            &:before {
                background-color: variables.$color-primary;
            }
        }
        
    }
    &__inner {
        cursor:pointer;
        position:relative;
        display:flex;
        flex-direction: row;
        &--has-description {
            align-items: flex-start;
        }
        &--no-description {
            align-items: center;
        }
    }

    &__image-container {
        position:relative;
        &:before {
            content:'';
            position:absolute;
            top:0;
            left:-16px;
            bottom:0;
            width:6px;
            border-style:solid;
            border-width:1px;
            border-color: transparent;
            transition: border-color variables.$transition-duration-short;
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                &:before {
                    background-color: rgba(variables.$color-primary, 0.5);
                }
            }
        }
    }
    &__text-container {
        padding: var(--space-2xs) var(--space-s);
    }
    &__icon-container {
        padding: var(--space-2xs) var(--space-s);
        font-size: var(--step-2);
        margin-left:auto;
        display:flex;
    }
    &__icon {
        width: var(--step-3);
        height: var(--step-3);
        min-width: 28px;
        min-height: 28px;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position:relative;
        color: variables.$color-primary;
        &:before {
            content:'';
            position:absolute;
            top:6px;
            left:6px;
            right:6px;
            bottom:6px;
            border:1px solid variables.$color-soft-white-2;
        }
    }

    &__title {
        font-size: var(--step-1);
        margin-bottom: var(--space-2xs);
        line-height: 1.5;
        &:only-child {
            margin-bottom: 0;
        }
    }
    &__description {
        font-size: var(--step-custom-small--1);
        margin:0;
    }
}