@use "../../scss/variables";

$label-font-size: var(--step-custom-small--1);
$radio-size: var(--space-s);
$radio-border-size:1px;
$border-color: variables.$color-font-primary-inverted;
$border-color-highlighted: variables.$color-primary;
$input-margin-bottom: var(--space-s);
$input-padding-vertical: var(--space-2xs);
$input-padding-horizontal: var(--space-xs);

.input-label-wrapper {
    margin-bottom: $input-margin-bottom;
    color:inherit;
    label {
        display:block;
        font-size: $label-font-size;
        transition: color variables.$transition-duration-short;
    }
    input, select, textarea {
        width:100%;
        color: inherit;
        background-color:transparent;
        font-size:16px;
        font-family: variables.$font-family-primary;
        transition: border-color variables.$transition-duration-short;
        &:focus {
            & +label {
                color: $border-color-highlighted;
            }
        }
    }
    input, select {
        padding: $input-padding-vertical $input-padding-horizontal;
        border-style:solid;
        border-width:1px;
        border-color: $border-color;
        &:focus {
            outline:none;
            border-color: $border-color-highlighted;
        }
    }
    select {
        option {
            background-color: black;
        }
    }
    textarea {
        padding: $input-padding-vertical $input-padding-horizontal;
        min-height: 200px;
        @media (max-width: 450px) {
            min-height: 100px;
        }
        border-width:1px;
        border-style:solid;
        border-color: $border-color;
        resize: vertical;
        &:focus {
            outline:none;
            border-color: $border-color-highlighted;
        }
    }
    &--error {
        input, select, textarea {
            border-color: variables.$color-error;
        }
        label {
            color: variables.$color-error;
        }
    }
}
.radio-button, .checkbox {
    position:relative;
    display:flex;
    flex-direction: row;
    align-items: flex-start;
    label {
        transition: color variables.$transition-duration-short;
    }
    input[type=radio], input[type=checkbox] {
        opacity: 0;
        position: absolute;
        width: var(--step-3);
        height: var(--step-3);
        min-width: 28px;
        min-height: 28px;
        cursor:pointer;
    }
    &__checkmark {
        pointer-events: none;
        font-size: var(--step-2);
        display:flex;
    }
    &__checkmark-inner {
        width: var(--step-3);
        height: var(--step-3);
        min-width: 28px;
        min-height: 28px;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position:relative;
        color: variables.$color-primary;
        svg {
            position:relative;
            z-index:1;
        }
        &:before {
            content:'';
            position:absolute;
            top:6px;
            left:6px;
            right:6px;
            bottom:6px;
            border:1px solid variables.$color-soft-white-2;
        }
    }
}
.radio-button {
    margin-right: var(--space-l);
    &:last-child, &:only-child{
        margin-right:0;
    }
    input[type=radio] +label {
        &:before {
            border-radius: 100%;
        }
        &:after {
            border-radius: 100%;
        }
    }


}
.checkbox-single {
    margin-bottom: var(--space-l);
    .checkbox {
        margin-bottom:0 ;
    }
}
.checkbox {
    &:last-child, &:only-child{
        margin-bottom:0;
    }
}
.radio-button-group, .checkbox-group {
    border:none;
    padding:0;
    margin-bottom: $input-margin-bottom;
    legend {
        font-size: var(--step-0);
        margin-bottom: var(--space-m);
        width:100%;
    }
    &__radio-wrap, &__checkbox-wrap {
        display:flex;
        flex-direction: row;
        width:100%;
    }
}