@use "../../scss/mixins";

.header {
    position: relative;
    padding: var(--space-s);
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .logo {
        position: relative;
        width:20vw;
        max-width:200px;
        min-width: 100px;
        z-index:1;
        svg {
            display:block;
        }
        @media screen and (max-width: 420px) {
            max-width:100px;
            min-width:90px;
        }
    }
    &:after {
        content:'';
        position:absolute;
        top:0;
        left:0;
        right:0;
        height:140%;
        background-color: RGB(var(--color-raw-bg-gradient-1));
        transition: background-color 800ms;
        mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        z-index:0;
    }
    .progress, .global-price {
        z-index:1;
    }
    &--desktop {
        .logo {
            margin-right:auto;
        }
        .save-button, .global-price {
            margin-left: var(--space-s);
        }
    }
    &--mobile {
        display:flex;
        flex-direction: column;
        .header__mobile-wrapper {
            width:100%;
            display:flex;
            flex-direction: row;;
            justify-content: center;
            align-items: center;
            margin-bottom: var(--space-3xs);
            &-language, &-logo, &-save-button {
                flex:1;
            }
            &-logo {
                display:flex;
                justify-content: center;
            }
            &-language {
                margin-right:auto;
            }
            &-save-button {
                margin-left:auto;
                display:flex;
                justify-content: flex-end;
            }
        }
    }
}