.accordeon {
    width:100%;
    &__title {
        width:100%;
        display:flex;
        align-items: center;
        justify-content: space-between;
        cursor:pointer;
    }
    &__content {
        overflow: hidden;
        width:100%;
        text-align: left;
    }
}