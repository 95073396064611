@use "../../scss/mixins";
@use "../../scss/variables";

.hotspots {
    position:absolute;
    z-index:2;
    width:100%;
    height:100%;
    top:0;
    left:0;
    pointer-events: none;
    @include mixins.reset-ul;
    &__item {
        position:absolute;
        left:0;
        top:0;
        transform-origin: left center;
       
        &-inner {
           
        }
        &-innermost {
            width:auto;
            transform:translateX(-50%);
            min-height:0 !important;
            text-align:center;
            line-height: variables.$line-height-headlines;
            background-color: variables.$color-primary;
            color: variables.$color-font-primary-inverted;
            font-size: var(--step-custom-small--4);
            text-transform: uppercase;
            font-weight: variables.$font-weight-bold;
            display:inline-flex;
            padding: var(--space-3xs-2xs) var(--space-2xs-xs);
            max-width:100px;
            user-select:none;
            box-shadow: 0 13px 20px -6px rgba(0, 0, 0, 0.25);
        }
        &--visible {
            .hotspots__item-innermost {
                opacity:1;
                pointer-events: all;
                cursor:pointer;
            }
        }
        &--hidden {
            .hotspots__item-innermost {
                opacity:0;
            }
        }
    }
}