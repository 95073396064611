@use "../../scss/variables";

.app {
    height:100svh;
    width:100svw;
    position:relative;
    top:0;
    left:0;
    overflow:hidden;
    .loading-indicator { 
        position:absolute;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
    }
    .testbutton {
        position: absolute;
        top:var(--space-2xl);
        left:var(--space-2xl);
    }
}

