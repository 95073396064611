@use "../../scss/variables";
@use "../../scss/mixins";

.lightbox-content-simple {
    padding:var(--space-2xl);
    background-color: variables.$color-font-primary;
    color: variables.$color-font-primary-inverted;
    text-align:center;
    .btn {
        @include mixins.button;
        width:100%;
        margin-top: var(--space-m);
        &--main {
            @include mixins.button-primary;
            margin-top: var(--space-s);
        }
    }
    &__save {
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: var(--space-2xl);
    }
    &__qr {
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        &-code {
            max-width:160px;
        }
    }
    &__text-block {
        margin-bottom: var(--space-m);
    }
    &__other-block {}
    &__input {
        position:absolute;
        opacity:0;
        pointer-events: none;
        //visibility: hidden;
    }
    h2 {
        font-size: var(--step-4);
    }
}

