@use "../../scss/mixins";
@use "../../scss/variables";

.sub-option-text-only {
    @include mixins.reset-button;
    color: variables.$color-font-primary-inverted;
    width:100%;
    position:relative;
    &:after, &:before {
        border-width: 1px;
        border-color: variables.$color-soft-white-2;
        position:absolute;
    }
    &:before {
        content:'';
        height:100%;
        width:var(--space-s);
        border-left-style: solid;
        left:0;
        top:0;
    }
    &:after {
        content:'';
        height:50%;
        width:var(--space-2xs);
        border-bottom-style: solid;
        left:0;
        top:0;
    }
    &:last-child {
        &:before {
            height:50%;
        }
    }
    &--has-description {
        margin-bottom: var(--space-s);
    }
    &--active:not(.sub-option-text-only--toggle) {
        pointer-events: none;
    }
    &__inner {
        cursor:pointer;
        position:relative;
        display:flex;
        flex-direction: row;
        align-items: center;
        pointer-events: all;
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                .sub-option-text-only__text-container {
                    &:before {
                        background-color: rgba(variables.$color-primary, 0.5);
                    }
                }
            }
        }
    }
    &__text-container {
        padding-left: var(--space-s);
    }
    &__icon-container {
        padding: var(--space-3xs) var(--space-s);
        font-size: var(--step-2);
        margin-left:auto;
        display:flex;
    }
    &__icon {
        width: var(--step-3);
        height: var(--step-3);
        min-width: 28px;
        min-height: 28px;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position:relative;
        color: variables.$color-primary;
        svg {
            position:relative;
            z-index:1;
        }
        &:before {
            content:'';
            position:absolute;
            top:6px;
            left:6px;
            right:6px;
            bottom:6px;
            border:1px solid variables.$color-soft-white-2;
        }
    }

    &__title {
        margin:0;
        font-size: var(--step-custom-small--2);
        @media (max-width: 420px) {
            font-size: var(--step-0);
        }
        line-height: 1.5;
        span {
            @include mixins.option-price;
        }
        &:only-child {
            margin-bottom: 0;
        }
    }
}