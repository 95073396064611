.html-tester {
    position:fixed;
    padding: var(--space-2xl);
    left: var(--space-2xl);
    top: var(--space-2xl);
    width:40vw;
    height:80vh;
    color: black;
    background-color: white;
    overflow-y: scroll;
}