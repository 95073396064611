@use "../../../scss/variables";

.navigation-info {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    pointer-events: none;
    @media screen and (aspect-ratio >= 1) and (max-width:1080px) {
        display:none;
    }
    @media screen and (aspect-ratio < 1) and (max-width:700px) {
        display:none;
    }
    &:before {
        content:'';
        display:block;
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background-color: RGB(var(--color-raw-bg-gradient-1));
        transition: background-color 800ms;
        mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 1) 91%);
    }
    &__inner {
        position: absolute;
        bottom:0;
        left:0;
        width: calc(100% - clamp(0px, 40vw, 660px));
        padding: 0 var(--space-xl);
        padding-bottom: var(--space-xl);
        @media screen and (orientation: portrait) {
            padding-bottom: variables.$max-height-option-window-portrait;
            width:100%;
        }
    }
    &__innermost {
        position: relative;
        svg {
            width:100%;
            opacity:0.8;
        }
    }
    &__number {
        position: absolute;
        left:50%;
        transform: translate(-50%, -50%);
        top:88%;
        color: var(--color-font-primary-soft-dynamic);
        transition: color variables.$transition-duration-long;
        font-size: var(--step-custom-small--2);
        text-transform: uppercase;
        letter-spacing: 0.05em;
    }

    &__menu {
        padding-top: var(--space-s);
        align-items: center;
        width:100%;
        display:flex;
        flex-direction: row;
        //color: var(--color-font-primary-soft-dynamic);
        color: variables.$color-primary;
        transition: color variables.$transition-duration-long;
        font-size: var(--step-custom-small--4);
        text-transform: uppercase;
        letter-spacing: 0.05em;
        justify-content: space-between;
        &-item {
            pointer-events: none;
            display:flex;
            flex-direction: row;
            align-items: center;
            svg {
                font-size: var(--step-2);
                margin-right: var(--space-xs);
            }
            pointer-events: all;
            cursor:pointer;
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    color: variables.$color-primary;
                }
            }
        }
    }
    &__disclaimer {
        color: var(--color-font-primary-soft-dynamic);
        display:flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        span {
            &:nth-child(3) {
                opacity:0.6;
                margin-top: var(--space-3xs);
            }
        }
    }
}