.color-swatch {
    margin: 0 var(--space-3xs);
    cursor:pointer;
    &__inner {}
    &__innermost { 
        border-radius:50%;
        width: var(--space-m-l);
        height: var(--space-m-l);
        border:2px solid transparent;
        &--active {
            border-color: white;
            box-shadow:inset 0 0 5px 3px rgba(0,0,0,0.2);
        }
    }
}