@use "../../scss/mixins";
@use "../../scss/variables";

.sub-options {
    overflow:hidden;
    &__inner {
        @include mixins.reset-ul;
        display:flex;
        margin: 0 calc(var(--space-3xs)*-1);
        padding: var(--space-2xs) 0 0 0;
        &--color-swatches {
            flex-direction: row;
        }
        &--text-only {
            flex-direction: column;
            padding-left: var(--space-s);
        }
    }
}