@use "../../scss/variables";

.logo {
    display:block;
    .cls-1 {
        fill: var(--color-font-primary-dynamic);
        transition: fill variables.$transition-duration-long;
    }

    .cls-2 {
        fill: #e10a17;
    }
}