@use "../../scss/mixins";
@use "../../scss/variables";

.option-window {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    pointer-events: none;
    user-select:none;
    &__inner {
        position:absolute;
        z-index:2;
        padding: var(--space-s-xl);
        pointer-events: none;
        user-select:none;
        @media (max-width: 450px) {
            padding:0;
        }
        @media screen and (orientation: landscape) {
            width: 40vw;
            max-width: 660px;
            right:0;
            top:50%;
            transform:translateY(-50%);
            .option-window__innermost {
                max-height: 84svh;
            }
        }
        @media screen and (orientation: portrait) {
            right:0;
            left:0;
            bottom:0;
            .option-window__innermost {
                max-height: variables.$max-height-option-window-portrait;
            }
        }
    }
    &__innermost {
        display:flex;
        flex-direction: column;
        pointer-events: all;
    }
    &__option-content {
        background-color: black;
        display:flex;
        flex-direction: column;
        min-height:0;
        &-inner {
            padding: var(--space-s-m) var(--space-m-l);
            padding-bottom: var(--space-xl-2xl);
            mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) var(--space-l), rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
            overflow-y: auto;
            @media screen and (orientation: portrait) and (max-width: 600px) {
                padding-top: var(--space-xl);
            }
        }
    }
    &__option-group {
        &:not(:last-child) {
            margin-bottom: var(--space-l);
        }
        &-title {
            color: variables.$color-soft-white-3;
            font-family: variables.$font-family-primary;
            font-size: var(--step-custom-small--1);
            border-bottom: 1px solid variables.$color-soft-white;
        }
    }
    &__mandatory-hint {
        color: variables.$color-soft-white-3;
        font-size: var(--step-custom-small--2);
        margin-top: var(--space-s);
    }
    &__text-content {
        color: white;
        position: relative;
        z-index:2;
        &-title {
            display:flex;
            justify-content: center;
            h1,h2,h3,h4,h5,h6 {
                $font-size: var(--step-5);
                $padding: var(--space-xs-s);
                font-size: $font-size;
                transform:translateY( calc((#{$font-size} * #{variables.$line-height-headlines} + #{$padding})*0.5));
                @media (max-width:400px) {
                    $font-size-mobile: var(--step-2);
                    font-size: $font-size-mobile;
                    transform:translateY( calc((#{$font-size-mobile} * #{variables.$line-height-headlines} + #{$padding})*0.5));
                }
                width:80%;
                background-color: white;
                color: variables.$color-font-primary;
                display: inline-block;
                padding: $padding var(--space-l);
            }
        }
        &-description {
            padding: var(--space-l);
            padding-top: var(--space-xl);
            background-color: black;
            border-bottom: 1px solid variables.$color-soft-white;
            font-size: var(--step-custom-small--1);
            @media screen and (orientation: portrait) and (max-width: 600px) {
                display:none;
            }
        }
    }
    &__button-content {
        background-color: black;
        border-top: 1px solid variables.$color-soft-white;
        padding: var(--space-m-l);
        display:flex;
        flex-direction: column;
        @media screen and (orientation: portrait) and (max-width: 420px) {
            flex-direction: row-reverse;
            justify-content: space-between;
        }
        justify-content: center;
        .btn {
            @include mixins.button;
            @include mixins.button-primary;
            @media screen and (orientation: portrait) and (max-width: 420px) {
                flex-grow:1;
            }
        }
    }
    &__back-button {
        @include mixins.back-button;
        margin-top: var(--space-s);
        @media screen and (orientation: portrait) and (max-width: 420px) {
            margin-top:0;
            margin-right:var(--space-s);
        }
    }
}