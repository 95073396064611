@use "../../scss/mixins";
@use "../../scss/variables";

.intro {
    position:absolute;
    padding: var(--space-s-xl);
    @media (max-width: 450px) {
        padding:0;
    }
    right:0;
    left:0;
    bottom:0;
    top:0;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    pointer-events: none;
    background:linear-gradient(0deg, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0) 40%);
    &__inner {
        pointer-events: all;
        display:flex;
        flex-direction: column;
        text-align: center;
        position: relative;
    }
    &__title {
        z-index:2;
        display: inline-block;
        position:relative;
        h1 {
            @media (orientation: portrait) {
                max-width:70vw;
            }
            @media (orientation: landscape) {
                max-width:45vw;
            }
            background-color: white;
            font-size: var(--step-7);
            @media (max-width:400px) {
                $font-size: var(--step-3);
            }
            display: inline-block;
            padding: var(--space-s-l);
            transform:translateY( calc((var(--step-5) * #{variables.$line-height-headlines} + var(--space-s-l))*0.5)); 
        }
    }
    &__content {
        @media (orientation: portrait) {
            
        }
        @media (orientation: landscape) {
            max-width:60vw;
        }
        background-color: black;
        color: variables.$color-font-primary-inverted;
        padding: var(--space-s-xl);
        padding-top: var(--space-2xl);
        font-size: var(--step-0);
    }
    &__text {
    }
    &__button-content {
        padding-top: var(--space-m-l);
        display:flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .btn {
            @include mixins.button;
            @include mixins.button-primary;
        }
    }
}