@use "../../scss/variables";

.global-price {
    font-weight: variables.$font-weight-bold;
    color: var(--color-font-primary-dynamic);
    display:flex;
    align-items: center;
    cursor:pointer;
    .package-svg-icon {
        display:block;
        width:var(--step-2);
        margin-right: var(--space-3xs);
        align-items: center;
        .fill-color-black {
            fill: var(--color-font-primary-dynamic);
        }
    }
}