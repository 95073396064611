@use "../../scss/mixins";
@use "../../scss/variables";

.save-button {
    position: relative;
    z-index: 1;
    &__item {
        position:relative;
        @include mixins.button-typography;
        user-select: none;
        font-size: var(--step-custom-small--4);
        font-weight: variables.$font-weight-bold; 
        display:inline-flex;
        justify-content: center;
        
        
        background-color: variables.$color-font-primary;
        color: white;
        margin:0;
        cursor:pointer;
        border-color: variables.$color-font-primary;
        padding: var(--space-3xs) var(--space-3xs);
        border-style: solid;
        border-width: 1px;
        white-space: nowrap;
        transition: border-color variables.$transition-duration-long, background-color variables.$transition-duration-long;
        
    }
}