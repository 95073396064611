$line-height: 1.75;
$line-height-headlines: 1.2;

$color-primary: #e10a17;
$color-primary-disabled: rgba(225, 10, 23, 0.35);
$color-secondary: #5da9c9;
$color-error: #e10a17;

$color-font-primary: rgb(0,0,0);
$color-font-primary-soft: rgba(0,0,0,0.5);
$color-font-primary-inverted: rgb(255,255,255);
$color-font-primary-disabled: rgba(0,0,0,0.3);

:root {
    //Are also being set in ThreeD.ts
    --color-font-primary-dynamic: rgba(0,0,0,1);
    --color-font-primary-invisible-dynamic: rgba(0,0,0,0);
    --color-font-primary-soft-dynamic: rgba(0,0,0,0.5);
    --color-font-primary-inverted-dynamic: rgba(255,255,255,1);
    --color-font-primary-disabled-dynamic: rgba(0,0,0,0.3);
    --color-font-primary-disabled: rgba(0,0,0,0.3);
    --color-raw-bg-gradient-1: "255,255,255";
    --color-raw-bg-gradient-2: "255,255,255";
}

$color-soft-white: rgb(60,60,60);
$color-soft-white-2: rgba(255,255,255,0.5);
$color-soft-white-3: rgba(255,255,255,0.7);

$color-temp: #131d30;
$color-temp2: #678cb7;

$font-weight-bold: 800;
$font-weight-regular: 400;

$font-weight-bold-headlines: 600;
$font-weight-regular-headlines: 400;

$font-family-primary: 'Noto Sans', sans-serif;
$font-family-headlines: 'Noto Serif', serif;

$transition-duration-short: 300ms;
$transition-duration-long: 800ms;


$max-height-option-window-portrait: 50svh;

