@use "../../scss/variables";
@use "../../scss/mixins";

.contact {
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    pointer-events: none;
    user-select:none;
    &__inner {
        position:absolute;
        z-index:2;
        padding: var(--space-s-xl);
        @media (max-width: 450px) {
            padding:0;
        }
        @media screen and (orientation: landscape) {
            width: 40vw;
            max-width: 660px;
            right:0;
            top:50%;
            transform:translateY(-50%);
            .contact__innermost {
                max-height: 75svh;
            }
        }
        @media screen and (orientation: portrait) {
            right:0;
            left:0;
            bottom:0;
            .contact__innermost {
                max-height: variables.$max-height-option-window-portrait;
            }
        }
    }
    &__innermost {
        display:flex;
        flex-direction: column;
        pointer-events: all;
        color: variables.$color-font-primary-inverted;
    }
    &__text-content {
        color: white;
        &-title {
            display:flex;
            justify-content: center;
            position:relative;
            z-index:2;
            h1,h2,h3,h4,h5,h6 {
                $font-size: var(--step-5);
                $padding: var(--space-xs-s);
                transform:translateY( calc((#{$font-size} * #{variables.$line-height-headlines} + #{$padding})*0.5));
                font-size: $font-size;
                @media (max-width:400px) {
                    $font-size-mobile: var(--step-2);
                    font-size: $font-size-mobile;
                    transform:translateY( calc((#{$font-size-mobile} * #{variables.$line-height-headlines} + #{$padding})*0.5));
                }
                width:80%;
                background-color: white;
                color: variables.$color-font-primary;
                display: inline-block;
                padding: $padding var(--space-l);
            }
        }
    }
    &__form-content {
        position:relative;
        background-color: black;
        display:flex;
        flex-direction: column;
        min-height:0;
        &-description {
            font-size: var(--step-custom-small-1);
            padding-top: var(--space-xl);
            margin-bottom: var(--space-m);
        }
        &-inner {
            padding: var(--space-m-l);
            padding-bottom: var(--space-3xl);
            mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) var(--space-l), rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
            overflow-y: auto;
            form {
                display:flex;
                flex-direction: row;
                flex-wrap: wrap;
                > * {
                    width:100%;
                    &:nth-child(1), &:nth-child(2) {
                        width:50%;
                    }
                    &:nth-child(1){
                        padding-right: var(--space-2xs);
                    }
                    &:nth-child(2){
                        padding-left: var(--space-2xs);
                    }
                    &:nth-child(3) {
                        width:66.66%;
                    }
                    &:nth-child(4) {
                        width:33.33%;
                    }
                    &:nth-child(3){
                        padding-right: var(--space-2xs);
                    }
                    &:nth-child(4){
                        padding-left: var(--space-2xs);
                    }
                }
            }
        }
        &-button-container {
            display:flex;
            flex-direction: column;
        }
        &-spinner-overlay {
            backdrop-filter: blur(8px);
            background-color: rgba(0,0,0,0.1);
            position:absolute;
            z-index:1;
            top:0;
            left:0;
            right:0;
            bottom:0;
        }
    }
    .btn {
        @include mixins.reset-button;
        @include mixins.button;
        width:100%;
    }
}