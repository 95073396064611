@use "./variables";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* old @link https://utopia.fyi/type/calculator?c=320,13,1.125,2560,17,1.25,7,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l|m-xl|xs-m|2xs-s|3xs-xs|l-2xl|xl-3xl&g=s,l,xl,12 */

/* @link https://utopia.fyi/type/calculator?c=320,13,1.125,2560,17,1.125,7,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l|m-xl|xs-m|2xs-s|3xs-xs|l-2xl|xl-3xl&g=s,l,xl,12 */

:root {
    --step--2: clamp(0.642rem, 0.6138rem + 0.1411vw, 0.8395rem);
    --step--1: clamp(0.7222rem, 0.6905rem + 0.1587vw, 0.9444rem);
    --step-0: clamp(0.8125rem, 0.7768rem + 0.1786vw, 1.0625rem);
    --step-1: clamp(0.9141rem, 0.8739rem + 0.2009vw, 1.1953rem);
    --step-2: clamp(1.0283rem, 0.9831rem + 0.226vw, 1.3447rem);
    --step-3: clamp(1.1569rem, 1.106rem + 0.2543vw, 1.5128rem);
    --step-4: clamp(1.3015rem, 1.2443rem + 0.286vw, 1.7019rem);
    --step-5: clamp(1.4642rem, 1.3998rem + 0.3218vw, 1.9147rem);
    --step-6: clamp(1.6472rem, 1.5748rem + 0.362vw, 2.154rem);
    --step-7: clamp(1.8531rem, 1.7716rem + 0.4073vw, 2.4232rem);
}

//Custom steps added by hand
:root {
    --step-custom-small--1: clamp(13px, 0.95rem, 0.95rem);
    --step-custom-small--2: clamp(13px, 0.85rem, 0.85rem);
    --step-custom-small--3: clamp(12px, 0.75rem, 0.75rem);
    --step-custom-small--4: clamp(10px, 0.5rem, 0.5rem);
}


html {
    color: variables.$color-font-primary;
    font-family: variables.$font-family-primary; 
    font-weight: variables.$font-weight-regular;
    line-height: variables.$line-height;
    font-size: 16px;
}

body {
    font-size: var(--step-0);
}

h1,h2,h3,h4,h5,h6 { 
    font-family: variables.$font-family-headlines;
    line-height: variables.$line-height-headlines;
    font-weight: variables.$font-weight-regular-headlines;
    margin-top:0;
    margin-bottom: var(--space-xs);
    &:only-child {
        margin:0;
    }
}

h1 {
    font-size: var(--step-3);
}
h2 {
    font-size: var(--step-2);
}
h3 {
    font-size: var(--step-1);
}
p {
    margin: var(--space-xs) 0;
    &:first-child {
        margin-top:0;
    }
    &:last-child {
        margin-bottom:0;
    }
    &:only-child {
        margin:0;
    }
}
a {
    //reset all styles on an a tag
    text-decoration: none;
    color: inherit;
    font-weight: variables.$font-weight-bold;
    font-size: inherit;
    line-height: inherit;
    &:hover {
        text-decoration: none;
        color: inherit;
    }
}
.link {
    color: red;
    text-decoration: underline;
    transition: color 300ms;
    &:hover {
        color: darken(red, 10%);
        text-decoration: underline;
    }
}
b, strong {
    font-weight: variables.$font-weight-bold;
}