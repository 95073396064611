@use "../../scss/mixins";
@use "../../scss/variables";

.sportSupersportIntro {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    pointer-events: none;
    user-select:none;
    &__inner {
        position:absolute;
        z-index:2;
        padding: var(--space-s-xl);
        pointer-events: all;
        display:flex;
        flex-direction: column;
        text-align: center;
        @media (max-width: 450px) {
            padding:0;
        }
        @media screen and (orientation: landscape) {
            width: 50vw;
            right:0;
            top:50%;
            transform:translateY(-50%);
            .option-window__innermost {
                max-height: 84svh;
            }
        }
        @media screen and (orientation: portrait) {
            right:0;
            left:0;
            bottom:0;
            .option-window__innermost {
                max-height: variables.$max-height-option-window-portrait;
            }
        }
    }
    &__title {
        z-index:2;
        display: inline-block;
        position:relative;
        h1 {
            @media (orientation: portrait) {
                max-width:70vw;
            }
            @media (orientation: landscape) {
                max-width:45vw;
            }
            background-color: white;
            font-size: var(--step-7);
            @media (max-width:500px) { 
                font-size: var(--step-3);
            }
            display: inline-block;
            padding: var(--space-s-l);
            transform:translateY( calc((var(--step-5) * #{variables.$line-height-headlines} + var(--space-s-l))*0.5));
        }
    }
    &__content {
        @media (orientation: portrait) {
            
        }
        @media (orientation: landscape) {
            max-width:60vw;
        }
        background-color: black;
        color: variables.$color-font-primary-inverted;
        padding: var(--space-s-xl);
        padding-top: var(--space-2xl);
        font-size: var(--step-0);
    }
    &__text {
        margin-bottom: var(--space-l);
    }
    &__tiles {
        display:flex;
        flex-direction: row;
        margin: 0 calc(var(--space-2xs-s)*-1);
        &-item {
            width:50%;
            padding: var(--space-2xs-s);
            text-align: left;
            
            &-inner {
                position: relative;
                height:100%;
                cursor: pointer;
                padding: var(--space-s);
                @include mixins.option-border;
                display:flex;
                flex-direction: column;
                align-items: flex-start;
                &:before {
                    content:'';
                    position:absolute;
                    top:0;
                    left:-6px;
                    bottom:0;
                    width:6px;
                    border-style:solid;
                    border-width:1px;
                    border-color: transparent;
                    transition: border-color variables.$transition-duration-short, background-color variables.$transition-duration-short;
                }
                
                h1{
                    margin:0;
                    font-size: var(--step-6);
                    margin-bottom: var(--space-xs);
                    display:flex;
                    .package-svg-icon {
                        display:block;
                        width:var(--step-7);
                        margin-right: var(--space-2xs);
                        align-items: center;
                    }
                    @media (max-width: 600px) {
                        font-size: var(--step-3);
                        .package-svg-icon {
                            width:var(--step-3);
                        }
                    }
                }
                p {
                    margin:0;
                    margin-bottom: var(--space-l);
                    color: variables.$color-soft-white-3;
                    @media (max-width: 600px) {
                        display:none;
                    }
                } 
                h2 {
                    margin-top: auto;
                    font-size: var(--step-3);
                    span {
                        font-family: variables.$font-family-primary;
                        font-weight: variables.$font-weight-bold;
                        font-size: var(--step-6);
                        @media (max-width: 600px) {
                            font-size: var(--step-3);
                        }
                    }
                }
                &--active {
                    &:before {
                        background-color: variables.$color-primary;
                    }
                }
            }
        }
    }
    &__button-content {
        padding-top: var(--space-m-l);
        display:flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .btn {
            @include mixins.button;
            &--main {
                @include mixins.button-primary;
                margin-top: var(--space-s);
            }
        }
    }
    &__back-button {
        @include mixins.back-button;
        margin-top: var(--space-s);
    }
}