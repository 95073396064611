@use "scss/variables";
@import "scss/typography";

/* @link https://utopia.fyi/space/calculator?c=320,14,1.2,2048,18,1.25,5,2,&s=0.75|0.5|0.25|0.125,1.5|2|3|4|6,s-l|m-xl|xs-m|2xs-s|3xs-xs|l-2xl|xl-3xl|s-xl&g=s,l,xl,12 */

:root {
  --space-4xs: clamp(0.13rem, calc(0.13rem + 0.00vw), 0.13rem);
  --space-3xs: clamp(0.25rem, calc(0.24rem + 0.06vw), 0.31rem);
  --space-2xs: clamp(0.44rem, calc(0.41rem + 0.12vw), 0.56rem);
  --space-xs: clamp(0.69rem, calc(0.65rem + 0.17vw), 0.88rem);
  --space-s: clamp(0.88rem, calc(0.83rem + 0.23vw), 1.13rem);
  --space-m: clamp(1.31rem, calc(1.24rem + 0.35vw), 1.69rem);
  --space-l: clamp(1.75rem, calc(1.66rem + 0.46vw), 2.25rem);
  --space-xl: clamp(2.63rem, calc(2.49rem + 0.69vw), 3.38rem);
  --space-2xl: clamp(3.50rem, calc(3.31rem + 0.93vw), 4.50rem);
  --space-3xl: clamp(5.25rem, calc(4.97rem + 1.39vw), 6.75rem);

  /* One-up pairs */
  --space-4xs-3xs: clamp(0.13rem, calc(0.09rem + 0.17vw), 0.31rem);
  --space-3xs-2xs: clamp(0.25rem, calc(0.19rem + 0.29vw), 0.56rem);
  --space-2xs-xs: clamp(0.44rem, calc(0.36rem + 0.41vw), 0.88rem);
  --space-xs-s: clamp(0.69rem, calc(0.61rem + 0.41vw), 1.13rem);
  --space-s-m: clamp(0.88rem, calc(0.72rem + 0.75vw), 1.69rem);
  --space-m-l: clamp(1.31rem, calc(1.14rem + 0.87vw), 2.25rem);
  --space-l-xl: clamp(1.75rem, calc(1.45rem + 1.50vw), 3.38rem);
  --space-xl-2xl: clamp(2.63rem, calc(2.28rem + 1.74vw), 4.50rem);
  --space-2xl-3xl: clamp(3.50rem, calc(2.90rem + 3.01vw), 6.75rem);

  /* Custom pairs */
  --space-s-l: clamp(0.88rem, calc(0.62rem + 1.27vw), 2.25rem);
  --space-m-xl: clamp(1.31rem, calc(0.93rem + 1.91vw), 3.38rem);
  --space-xs-m: clamp(0.69rem, calc(0.50rem + 0.93vw), 1.69rem);
  --space-2xs-s: clamp(0.44rem, calc(0.31rem + 0.64vw), 1.13rem);
  --space-3xs-xs: clamp(0.25rem, calc(0.13rem + 0.58vw), 0.88rem);
  --space-l-2xl: clamp(1.75rem, calc(1.24rem + 2.55vw), 4.50rem);
  --space-xl-3xl: clamp(2.63rem, calc(1.86rem + 3.82vw), 6.75rem);
  --space-s-xl: clamp(0.88rem, calc(0.41rem + 2.31vw), 3.38rem);
}

::-webkit-scrollbar {
  background-color: variables.$color-font-primary;
  width:8px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255,0.4);
  border-radius:10px;
}
::-webkit-scrollbar-track {
  background-color: variables.$color-font-primary;
}

* {
  box-sizing: border-box;
  scrollbar-width: none;
}

html {
  overflow:hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position:fixed;
  top:0;
  left:0;
  overflow:hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

//Change DAT GUI
.dg.ac {
  z-index:1000 !important;
}