@use "../../scss/mixins";
@use "../../scss/variables";

.text-only {
    color: variables.$color-font-primary-inverted;
    width:100%;
    margin-bottom: var(--space-3xs);
    &:last-child {
        margin-bottom:0;
    }
    &--has-description {
        margin-bottom: var(--space-2xs);
    }
    &--active:not(.text-only--toggle) {
        pointer-events: none;
        .text-only__text-container {
            &:before {
                background-color: variables.$color-primary;
            }
        }
    }
    &--package-disabled {
        opacity:0.75;
        background-color: rgba(255,255,255,0.2);
    }
    &__inner {
        @include mixins.reset-button;
        width:100%;
        cursor:pointer;
        position:relative;
        display:flex;
        flex-direction: row;
        align-items: center;
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                .text-only__text-container {
                    &:before {
                        background-color: rgba(variables.$color-primary, 0.5);
                    }
                }
            }
        }
        @include mixins.option-border;
    }
    &__text-container {
        padding: var(--space-3xs) var(--space-xs);
        &:before {
            content:'';
            position:absolute;
            top:0;
            left:-7px;
            bottom:0;
            width:6px;
            transition: border-color variables.$transition-duration-short, background-color variables.$transition-duration-short;
        }
    }
    &__icon-container {
        padding: var(--space-2xs) var(--space-s);
        font-size: var(--step-2);
        margin-left:auto;
        display:flex;
    }
    &__icon {
        width: var(--step-3);
        height: var(--step-3);
        min-width: 28px;
        min-height: 28px;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position:relative;
        color: variables.$color-primary;
        svg {
            position:relative;
            z-index:1;
        }
        &:before {
            content:'';
            position:absolute;
            top:6px;
            left:6px;
            right:6px;
            bottom:6px;
            border:1px solid variables.$color-soft-white-2;
        }
    }
    &__title {
        margin:0;
        font-size: var(--step-1);
        @media (max-width: 420px) {
            font-size: var(--step-0);
        }
        line-height: 1.5;
        vertical-align: center;
        &:only-child {
            margin-bottom: 0;
        }
        span {
            @include mixins.option-price;
        }
    }
    &__description, &__package-hint {
        font-size: var(--step-custom-small--3);
        color: variables.$color-soft-white-3;
        line-height:1.4;
    }
    &__description { 
        margin:0;
    }
    &__package-hint {
        display:inline-flex;
        align-items: center;
        margin-top:var(--space-xs);
        .package-svg-icon {
            display:block;
            width:var(--step-2);
            margin-right: var(--space-3xs);
            align-items: center;
        }
    }
}