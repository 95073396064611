@use "../../scss/mixins";
@use "../../scss/variables";

.progress {
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    @media screen and (orientation: landscape) {
        display:flex;
    }
    &__menu {
        @include mixins.reset-ul;
        display:flex;
        flex-direction: row;
        overflow-x:auto;
        &-item {
            position:relative;
            @include mixins.button-typography;
            user-select: none;
            font-size: var(--step-custom-small--4);
            font-weight: variables.$font-weight-bold;
            display:flex;
            align-items: center;
            transition: color variables.$transition-duration-long;
            &:before {
                transition: background-color variables.$transition-duration-long;
            }
            &-label {
                display: block;
                padding: var(--space-4xs) var(--space-4xs);
                border-style: solid;
                border-width: 1px;
                white-space: nowrap;
                transition: border-color variables.$transition-duration-long, background-color variables.$transition-duration-long;
                &-roman {
                    margin-right: var(--space-3xs);
                    @media screen and (orientation : portrait) ,screen and (max-width: 1200px) {
                        margin-right: 0;
                    }
                }
                &-text {
                    @media screen and (orientation : portrait) ,screen and (max-width: 1500px) {
                        display:none;
                    }
                }
            }
            &--completed {
                cursor: pointer;
                color: var(--color-font-primary-dynamic);
                &:before {
                    background-color: var(--color-font-primary-dynamic);
                }
                .progress__menu-item-label {
                    //border-color: var(--color-font-primary-dynamic);
                    border-color: transparent;
                    &-roman {
                        color: variables.$color-primary;
                    }
                }
            }
            &--future {
                pointer-events: none;
                color: var(--color-font-primary-disabled-dynamic);
                &:before {
                    background-color: var(--color-font-primary-disabled-dynamic);
                }
                .progress__menu-item-label {
                    //border-color: var(--color-font-primary-disabled-dynamic); 
                    border-color: transparent;
                    &-roman {
                        color: variables.$color-primary-disabled;
                    }
                }
            }
            &--active {
                color: var(--color-font-primary-dynamic);
                &:before {
                    background-color: var(--color-font-primary-dynamic);
                }
                .progress__menu-item-label {
                    border-style:solid;
                    //background-color: var(--color-font-primary-dynamic);
                    border-color: var(--color-font-primary-dynamic);
                    &-roman {
                        color: variables.$color-primary;
                    }
                }
            }

            &:not(:first-child) {
                &:before {
                    content:'';
                    display:block;
                    width: var(--space-4xs-3xs);
                    height: 1px;
                    margin: 0 calc(var(--space-4xs));
                }
            }
        }
    }
}