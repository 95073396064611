@use "../../scss/variables";

.loading-indicator {
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    &__text {
        position: relative;
        text-transform: uppercase;
        background-color: white;
        padding: 0 var(--space-xs);
    }
    &__bar {
        position:absolute;
        left:50%;
        top:50%;
        transform:translateX(-50%);
        transition: width 200ms; 
        height:1px;
        width:0;
        background-color: variables.$color-font-primary;
    }
}