@use "../../scss/variables";
@use "../../scss/mixins";

.lightbox-content-summary {
    padding:var(--space-2xl);
    @media (max-width: 700px) {
        padding:var(--space-l);
    }
    background-color: variables.$color-font-primary;
    color: variables.$color-font-primary-inverted;
    width: min(100%, 740px);
    &__header {
        margin-bottom: var(--space-l);
        h1 {
            display:flex;
            align-items: center;
            font-size: var(--step-5);
            .package-svg-icon {
                width: var(--step-5);
                margin-right: var(--space-s);
            }
        }
    }
    &__category {
        margin-bottom: var(--space-m);
    }
    &__option-group {
        @include mixins.reset-ul;
        li {
            display:flex;
            justify-content: space-between;
            color: variables.$color-soft-white-3;
            align-items: flex-end;
            span {
                &:first-child {
                    overflow:hidden;
                    display:block;
                    flex-grow:1;
                    position:relative;
                    font-size: var(--step-custom-small--1);
                    padding-right: var(--space-s);
                    &:after {
                        content:".......................................................................................................................";
                        position:absolute;
                        padding-left: var(--space-2xs);
                        color: variables.$color-soft-white-2;
                    }
                }
                &:last-child {
                    font-weight: variables.$font-weight-bold;
                    position:relative;
                    &:before {
                        content:'';
                        background-color: black;
                        width: 10px;
                        height:100%;
                        position:absolute;
                        left: -10px;
                    }
                }
            }
            &.--no-price {
                span {
                    &:first-child {
                        &:after {
                            content:"";
                        }
                    }
                    &:last-child {
                        opacity:0;
                    }
                }
            }
        }
    }
    &__mandatory-hint {
        font-size: var(--step-custom-small--2);
        color: variables.$color-soft-white-3;
    }
    &__total {
        border-top: 2px solid white;
        margin-top: var(--space-s);
        padding-top: var(--space-s);
        display:flex;
        justify-content: space-between;
        align-items: flex-end;
        h1, span {
            line-height:1;
        }
        h1 {
            font-size: var(--step-7);
            margin:0;
            overflow:hidden;
            display:block;
            flex-grow:1;
            position:relative;
            padding-right: var(--space-s);
            &:after {
                content:".......................................................................................................................";
                position:absolute;
                padding-left: var(--space-2xs);
                color: variables.$color-soft-white-2;
            }
        }
        span {
            font-size: var(--step-5);
            font-weight: variables.$font-weight-bold;
            position:relative;
            &:before {
                content:'';
                background-color: black;
                width: 10px;
                height:100%;
                position:absolute;
                left: -10px;
            }
        }
    }
    &__additional-info {
        margin-top: var(--space-l);
        font-size: var(--step-custom-small--2);
        color: variables.$color-soft-white-3;
        text-align: center;
    }
    h2 {
        font-size: var(--step-1);
        margin-bottom: var(--space-3xs);
    }
    b {
        font-family: variables.$font-family-headlines;
    }
}

