@use "../../scss/variables";
@use "../../scss/mixins";

.lightbox-content-package-detail {
    padding:var(--space-2xl);
    background-color: variables.$color-font-primary;
    color: variables.$color-font-primary-inverted;
    width:100%;
    max-width:900px;
    &__text {
        margin-bottom: var(--space-m);
    }
    .btn {
        @include mixins.button;
        width:100%;
        margin-top: var(--space-m);
    }
    b {
        font-family: variables.$font-family-headlines;
    }
    table {
        width:100%;
        text-align: left;
        margin: 0 auto;
        border-collapse: collapse;
        th, td {
            padding: var(--space-3xs) var(--space-xs);
            border:1px solid variables.$color-soft-white;
        }
        td {
            &:nth-child(2), &:nth-child(3) {
                text-align: center;
            }
        }
    }
}

