@use "../../scss/variables";
@use "../../scss/mixins";

.lightbox {
    $title-font-size: var(--step-5);
    $title-font-size-mobile: var(--step-2);
    $padding: var(--space-xs-s);
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index:10;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,0.4);
    backdrop-filter: blur(10px);
    padding: var(--space-xs);
    &__inner {
        @media (min-width:1500px) {
            max-width:60%;
        }
        max-width: 1024px;
        min-height:0;
        max-height:100%;
        overflow: hidden;
        position:relative;

        display:flex;
        flex-direction: column;
        justify-content: stretch;
    }
    &__innermost {
        flex-grow:1;
        display:flex;
        flex-direction: column;
        align-items: center;
        width:100%;
        height:100%;
        position:relative;
        overflow-y: auto;
    }
    &__closer {
        @include mixins.reset-button;
        z-index:10;
        position:absolute;
        top: var(--space-s-m);
        right: var(--space-s-m);
        cursor:pointer;
        color: variables.$color-font-primary-inverted;
    }
    &__title {
        display:flex;
        justify-content: center;
        z-index:2;
        h1,h2,h3,h4,h5,h6 {
            text-align: center;
            position:relative;
            font-size: $title-font-size;
            transform:translateY( calc((#{$title-font-size} * #{variables.$line-height-headlines} + #{$padding})*0.5));
            @media (max-width:400px) {
                font-size: $title-font-size-mobile;
                transform:translateY( calc((#{$title-font-size-mobile} * #{variables.$line-height-headlines} + #{$padding})*0.5));
            }
            width:80%;
            background-color: white;
            color: variables.$color-font-primary;
            padding: $padding var(--space-l);

            display:inline-block;

            .lightbox__closer {
                position:absolute;
                right: var(--space-m);
                top:55%;
                transform:translateY(-50%);
                color: variables.$color-font-primary;
                margin:0;
            }
        }
    }
}
